// src/components/Leaderboard/Leaderboard.js

import React from 'react';
import './Leaderboard.css';
import profilePic from "../../assets/images/profile_image.png";
import Button from '../../components/Button/Button3';

const Leaderboard = ({
  leaderboardData,
  currentUserId,
  currentPage,
  totalPages,
  onPrevPage,
  onNextPage,
  onSearchChange,
  searchTerm
}) => {

  // Handler for local input change -> pass to parent
  const handleSearchInput = (e) => {
    onSearchChange(e.target.value);
  };

  return (
    <div className="leaderboard-container">
      {/* Search Box */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchInput}
          className="search-input"
        />
      </div>

      {/* Leaderboard List */}
      <div className="ranking">
        {leaderboardData.map((user) => (
          <div
            key={user._id}
            className={`ranking-item ${
              user._id.toString() === currentUserId.toString() ? 'highlight' : ''
            }`}
          >
            {/* 
              We expect the server to provide a 'rank' field 
              that includes the global rank for each user. 
            */}
            <span className="rank">{user.rank}.</span>
            <img
              src={user.profilePictureUrl || profilePic}
              alt={`${user.first_name} ${user.last_name}'s avatar`}
              className="avatar"
            />
            <span className="name">{`${user.first_name} ${user.last_name}`}</span>
            <span className="points">{user.points} pts</span>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination-controls">
          <span className="pagination-info">
            Page {currentPage} of {totalPages}
          </span>
          <div className="pagination-buttons">
            <Button
              variant="small"
              primaryText="Previous"
              onClick={onPrevPage}
              disabled={currentPage === 1}
              styleType={currentPage === 1 ? 'default' : 'active'}
            />
            <Button
              variant="small"
              primaryText="Next"
              onClick={onNextPage}
              disabled={currentPage === totalPages}
              styleType={currentPage === totalPages ? 'default' : 'active'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
