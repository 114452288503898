import React from 'react';
import './PrivacyPolicy.css'; // Optional: for styling, if needed

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: March 27, 2025</p>

      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
      </p>

      <p>
        We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the{' '}
        <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank" rel="noopener noreferrer">
          Privacy Policy Generator
        </a>{' '}
        and has been updated to include additional provisions required under European data protection laws (such as the GDPR).
      </p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>

      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where “control” means the ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
        <li><strong>Company</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to zealfc, located at Plac Wolnica 14/25, Krakow 31-060, Poland.</li>
        <li><strong>Cookies</strong> are small files placed on Your computer, mobile device or any other device by a website, containing details of Your browsing history on that website.</li>
        <li><strong>Country</strong> refers to: Poland.</li>
        <li><strong>Device</strong> means any device that can access the Service such as a computer, cellphone or digital tablet.</li>
        <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service</strong> refers to the Website.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. This includes third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service or assist the Company in analyzing how the Service is used.</li>
        <li><strong>Third-party Social Media Service</strong> refers to any website or social network website through which a User can log in or create an account to use the Service.</li>
        <li><strong>Usage Data</strong> refers to data collected automatically, generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
        <li><strong>Website</strong> refers to zealfc, accessible from <a href="https://www.zealfc.com/" target="_blank" rel="noopener noreferrer">https://www.zealfc.com/</a></li>
        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>

      <h2>Collecting and Using Your Personal Data</h2>
      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
        <li>Usage Data</li>
      </ul>

      <h4>Usage Data</h4>
      <p>
        Usage Data is collected automatically when using the Service. It may include information such as Your Device's IP address, browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may collect additional automatically generated information including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, and information about Your mobile operating system.
      </p>

      <h4>Information from Third-Party Social Media Services</h4>
      <p>
        If You register or log in to use the Service through a Third-Party Social Media Service (such as Google, Facebook, Instagram, Twitter, or LinkedIn), We may collect Personal Data associated with Your account on that service.
      </p>

      <h4>Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. These technologies may include web beacons, tags, and scripts.
      </p>
      <ul>
        <li>
          <strong>Necessary / Essential Cookies</strong> – These cookies are required to provide You with services available through the Website.
        </li>
        <li>
          <strong>Cookies Policy / Notice Acceptance Cookies</strong> – These cookies identify if users have accepted the use of cookies on the Website.
        </li>
        <li>
          <strong>Functionality Cookies</strong> – These cookies allow us to remember choices You make when using the Website.
        </li>
      </ul>
      <p>For more information about the cookies we use and your choices regarding cookies, please refer to our Cookies Policy.</p>

      <h2>Use of Your Personal Data</h2>
      <p>
        The Company may use Your Personal Data for the following purposes:
      </p>
      <ul>
        <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
        <li><strong>To manage Your Account</strong> and provide access to features available to registered users.</li>
        <li><strong>For the performance of a contract</strong>, including processing orders or providing services you have requested.</li>
        <li><strong>To contact You</strong> via email, telephone, SMS, or other communication methods regarding updates or information related to the Service.</li>
        <li><strong>For marketing purposes</strong>, to provide you with news, special offers, and general information about other goods, services, or events.</li>
        <li><strong>To manage Your requests</strong> and provide support.</li>
        <li><strong>For business transfers</strong>, such as in the event of a merger or acquisition.</li>
        <li><strong>For data analysis</strong> to improve our Service and user experience.</li>
      </ul>

      <h2>GDPR Compliance and Your Rights (For Users in the European Economic Area)</h2>
      <p>
        We are committed to complying with the General Data Protection Regulation (GDPR) and other applicable EU data protection laws. This means:
      </p>
      <ul>
        <li><strong>Lawful Basis for Processing:</strong> We process Your Personal Data only when we have a lawful basis to do so (such as Your consent, contract performance, or compliance with legal obligations).</li>
        <li><strong>Your Rights:</strong> You have the right to access, correct, delete, restrict, or object to the processing of Your Personal Data. You also have the right to data portability.</li>
        <li><strong>Data Protection Officer:</strong> For any questions or concerns regarding Your data, you may contact our Data Protection Officer at <a href="mailto:contact@zealfc.com">contact@zealfc.com</a>.</li>
        <li><strong>Supervisory Authority:</strong> If you are not satisfied with our handling of Your Personal Data, you have the right to lodge a complaint with the supervisory authority in your country.</li>
      </ul>

      <h2>Retention of Your Personal Data</h2>
      <p>
        We retain Your Personal Data only for as long as necessary to fulfill the purposes set out in this Privacy Policy or as required by law. Usage Data is generally retained for a shorter period unless needed for security or improvement purposes.
      </p>

      <h2>Transfer of Your Personal Data</h2>
      <p>
        Your Personal Data may be processed at our operating offices or on servers located outside of your jurisdiction. In such cases, we ensure that appropriate safeguards are in place to protect your data in accordance with applicable data protection laws.
      </p>

      <h2>Delete Your Personal Data</h2>
      <p>
        You have the right to request the deletion of Your Personal Data. You can update, amend, or delete your information by accessing your account settings or by contacting us at <a href="mailto:contact@zealfc.com">contact@zealfc.com</a>. Note that we may need to retain certain information to comply with legal obligations.
      </p>

      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred as part of that transaction, subject to a new Privacy Policy.
      </p>
      <h3>Law Enforcement</h3>
      <p>
        We may disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities.
      </p>
      <h3>Other Legal Requirements</h3>
      <p>
        We may disclose Your Personal Data in the good faith belief that such action is necessary to comply with a legal obligation, protect our rights, or prevent fraud.
      </p>

      <h2>Security of Your Personal Data</h2>
      <p>
        We take commercially reasonable measures to protect Your Personal Data. However, no method of transmission over the Internet or method of electronic storage is completely secure.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        Our Service does not target anyone under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have collected information from a child under 13, please contact us immediately.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to websites operated by third parties. We are not responsible for the content or privacy practices of these sites. We encourage you to read their Privacy Policies.
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the “Last updated” date. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our data practices, please contact us:
      </p>
      <ul>
        <li>By email: <a href="mailto:contact@zealfc.com">contact@zealfc.com</a></li>
        <li>By mail: zealfc, Plac Wolnica 14/25, Krakow 31-060, Poland</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
