import React from 'react';
import './CookiesPolicy.css';

const CookiesPolicy = () => {
  return (
    <div className="cookies-policy">
      <h1>Cookies Policy</h1>
      <p>Last updated: March 27, 2025</p>

      <p>
        This Cookies Policy explains how zealfc ("we", "us", "our") uses cookies and similar tracking technologies on our website (the "Service"). By using our Service, you consent to our use of cookies as described in this policy.
      </p>

      <h2>What Are Cookies?</h2>
      <p>
        Cookies are small text files stored on your device that help us operate our website, improve performance, and maintain security.
      </p>

      <h2>Cookies We Use</h2>
      <ul>
        <li>
          <strong>Strictly Necessary Cookies:</strong> Essential for the website’s operation and security. For example, <code>cf_clearance</code> protects against malicious traffic.
        </li>
        <li>
          <strong>Performance Cookies:</strong> Help us optimize website performance. For example, a cookie from Stripe (<code>m</code>) aids in caching and faster page loads.
        </li>
        <li>
          <strong>Functionality Cookies:</strong> Enable secure payment processing by storing session details (such as <code>__stripe_mid</code> and <code>__stripe_sid</code>).
        </li>
      </ul>

      <h2>Your Choices</h2>
      <p>
        You can manage or disable cookies via your browser settings. Note that doing so may affect the functionality and security of our Service.
      </p>

      <h2>Changes to This Policy</h2>
      <p>
        We may update this Cookies Policy periodically. The updated version will be posted on our website along with a new "Last updated" date.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Cookies Policy, please contact us at <a href="mailto:contact@zealfc.com">contact@zealfc.com</a>.
      </p>
    </div>
  );
};

export default CookiesPolicy;
