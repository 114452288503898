// src/components/ScrollToTop/ScrollToTop.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Immediately scroll window and document elements to top
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
    // If any element is focused, remove focus to prevent auto-scrolling
    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur();
    }
  }, [pathname]);

  return null;
}
