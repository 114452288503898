import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms">
      <h1>Terms and Conditions</h1>
      <p>Last updated: March 27, 2025</p>

      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the zealfc website (the "Service") operated by zealfc ("we", "us", or "our"). By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Service.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using our Service, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy, as well as any additional terms that may apply.
      </p>

      <h2>2. Accounts</h2>
      <p>
        When you create an account with us, you agree to provide accurate, complete, and current information and to update this information as needed. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.
      </p>

      <h2>3. Purchases, Subscriptions, and Free Trials</h2>
      <p>
        The Service offers subscription plans, one-time purchases, and may offer free trials. All purchases are subject to our Payment Processing Terms. Prices are subject to change without notice, but any changes will not affect transactions already in process.
      </p>
      <p>
        If a free trial is offered, it is provided solely at our discretion and may be subject to additional terms and conditions, which will be communicated to you at the time of sign-up.
      </p>

      <h2>4. User-Generated Content</h2>
      <p>
        You retain ownership of any content you submit to the Service. However, by posting or submitting content, you grant us a worldwide, non-exclusive, royalty-free, perpetual license to use, display, reproduce, distribute, and modify your content solely for the purpose of operating and improving our Service.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        All content on the Service, including text, graphics, logos, images, and software, is the exclusive property of zealfc or its licensors and is protected by international intellectual property laws. You agree not to reproduce, duplicate, copy, sell, or otherwise exploit any portion of the Service without express written permission from us.
      </p>

      <h2>6. Feedback</h2>
      <p>
        If you provide any feedback, suggestions, or ideas regarding the Service, you agree that we may use such feedback without any obligation to compensate you.
      </p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>
        The Service is provided "AS IS" and "AS AVAILABLE" without any warranty of any kind, whether express or implied. We do not guarantee that the Service will be uninterrupted, error-free, or completely secure. You use the Service at your own risk.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        In no event shall zealfc, its affiliates, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages (including loss of profits or data) arising from your access to or use of, or inability to access or use, the Service, even if we have been advised of the possibility of such damages.
      </p>

      <h2>9. Governing Law and Jurisdiction</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of Poland. Any disputes arising out of or relating to these Terms shall be resolved exclusively in the courts located in Krakow, Poland.
      </p>

      <h2>10. Changes to Terms</h2>
      <p>
        We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on the Service and updating the "Last updated" date. Your continued use of the Service after any changes constitutes acceptance of those changes.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:contact@zealfc.com">contact@zealfc.com</a>.
      </p>
    </div>
  );
};

export default Terms;
