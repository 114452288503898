import React, { useState, useCallback } from "react";
import "./ProfileInfo.css";
import profilePic from "../../assets/images/profile_image.png";
import Button from "../../components/Button/Button3";
import axios from "axios";
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/getCroppedImg';
import EditProfile from "../EditProfile/EditProfile";


const ProfileInfo = ({ userData }) => {
  const isMobile = window.innerWidth < 768;
  const aspectValue = isMobile ? 1 : (459.102 / 428.719);
  const cropSizeValue = isMobile ? { width: 200, height: 200 } : { width: 459.102, height: 428.719 };

  const [profileImageUrl, setProfileImageUrl] = useState(userData.profilePictureUrl || profilePic);
  const [selectedFile, setSelectedFile] = useState(null);

  const [showCropModal, setShowCropModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropImageSrc, setCropImageSrc] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const handleEditSuccess = () => {
    // Callback to be passed to EditProfile to close edit mode after a successful update
    setIsEditing(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 5 * 1024 * 1024;
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!allowedTypes.includes(file.type)) {
        alert('Only JPEG, PNG, and GIF files are allowed.');
        return;
      }

      if (file.size > maxSizeInBytes) {
        alert('File size is too large. Max limit is 5MB.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setCropImageSrc(reader.result);
        setShowCropModal(true);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  
  const handleConfirmCrop = async () => {
    try {
      if (!cropImageSrc || !croppedAreaPixels) {
        alert("Image or crop area not set correctly.");
        return;
      }
  
      const croppedBlob = await getCroppedImg(cropImageSrc, croppedAreaPixels);
      const croppedUrl = URL.createObjectURL(croppedBlob);
      setProfileImageUrl(croppedUrl);

      const croppedFile = new File([croppedBlob], selectedFile.name, { type: "image/png" });
      handleImageUpload(croppedFile);

      setShowCropModal(false);
    } catch (error) {
      console.error("Error cropping image:", error);
      alert("Failed to crop the image. Please try again.");
    }
  };

  const handleImageUpload = async (file) => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      alert("You must be logged in to perform this action.");
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/profile/upload-profile-picture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.profilePictureUrl) {
        const rawUrl = response.data.profilePictureUrl;
        const uniqueUrl = `${rawUrl}?t=${Date.now()}`;
        setProfileImageUrl(uniqueUrl);
        alert('Profile picture uploaded successfully!');
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      alert("Failed to upload profile picture. Please try again.");
    }
  };

  if (isEditing) {
    // Pass the handleEditSuccess callback to EditProfile
    return <EditProfile onEditSuccess={handleEditSuccess} />;
  }

  // Compute the total credits from userData.credits array
  const totalCredits = Array.isArray(userData.credits)
    ? userData.credits.reduce((sum, entry) => sum + (entry.amount || 0), 0)
    : 0;

  return (
    <div className="profile-info-container">
      <div className="profile-info">
      <div className="profile-image-wrapper">
          <div className="profile-image">
            <label htmlFor="profilePictureInput" className="image-label">
              <div className="overlay">
                <span>Update Picture</span>
              </div>
              <img src={profileImageUrl} alt={`${userData.first_name}'s profile`} />
            </label>
            <input
              type="file"
              id="profilePictureInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        
        <div className="profile-details">
          <p className="first-name">{userData.first_name}</p>
          <p>{userData.email}</p>
          <p>{userData.phone_number}</p>
          {/* Display the summed credits */}
          <p>{totalCredits} CREDITS</p>
          <Button
            variant="small"
            primaryText="Edit"
            styleType="default"
            onClick={() => setIsEditing(true)}
          />
        </div>
      </div>


      {showCropModal && (
        <div className="crop-modal">
          <div className="crop-container">
            <Cropper
              image={cropImageSrc}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              aspect={aspectValue}
              cropSize={cropSizeValue}

              cropShape="rect"
              mediaProps={{ style: { objectFit: 'cover' } }}
              style={{
                containerStyle: {
                  backgroundColor: '#ffff',
                  width: '100%',
                  height: '100%',
                  position: 'relative'
                },
                mediaStyle: {
                  background: 'transparent'
                },
                cropAreaStyle: {
                  background: 'transparent',
                  border: 'none'
                },
              }}
            />
          </div>

          <div className="zoom-slider-container">
            <input
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
              className="zoom-slider"
            />
          </div>

          <div className="crop-controls">
            <Button
              variant="small"
              primaryText="Confirm Crop"
              onClick={handleConfirmCrop}
              styleType="default"
            />
            <Button
              variant="small"
              primaryText="Cancel"
              onClick={() => setShowCropModal(false)}
              styleType="default"
            />
          </div>
        </div>
      )}

      <div className="noiseeffect"></div>
    </div>
  );
};

export default ProfileInfo;
