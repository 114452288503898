import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import icon from "../../assets/icons/icon.png";
import line from "../../assets/images/line.avif";
import profilePic from "../../assets/images/profile_image.png";

function Navbar({ isAuthenticated, handleLogout, onUserFetch, refreshTokens }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const handleItemClick = (path) => {
    setIsExpanded(false);
    navigate(path);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsExpanded(false);
    }
  };

  const handleLogoutClick = () => {
    setIsExpanded(false);
    handleLogout(); // Clear tokens and authentication
    navigate("/"); // Redirect to home after logout
  };

  useEffect(() => {
    // Only run if user is authenticated
    if (!isAuthenticated) return;
  
    let isMounted = true; // To prevent state updates if component unmounts
  
    const fetchUserData = async () => {
      let idToken = localStorage.getItem("idToken");
      if (!idToken) return;
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/profile/user-profile`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 401) {
          // Attempt token refresh
          const tokenRefreshed = await refreshTokens(); // from props
          if (tokenRefreshed) {
            idToken = localStorage.getItem("idToken");
            // Retry the user-profile fetch after refresh
            const retryResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/profile/user-profile`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
              }
            );
            if (!retryResponse.ok) throw new Error("Failed to fetch after refresh");
            const data = await retryResponse.json();
            if (isMounted) {
              setUserData(data);
              if (onUserFetch) onUserFetch(data);
            }
          } else {
            // If refresh fails, log the user out
            if (isMounted) handleLogoutClick();
          }
        } else {
          // If status isn't 401
          if (!response.ok) throw new Error("Failed to fetch user data");
          const data = await response.json();
          if (isMounted) {
            setUserData(data);
            if (onUserFetch) onUserFetch(data);
          }
        }
      } catch (err) {
        console.error(err);
        // Optionally handle errors (e.g. logout or show message)
      }
    };
  
    fetchUserData();
  
    return () => {
      // Clean up if component unmounts while fetch is in progress
      isMounted = false;
    };
  // Only depend on isAuthenticated
  }, [isAuthenticated]);
  

  return (
    <>
      <nav className={`navbar ${isExpanded ? "expanded" : ""}`}>
        <div className="navbar-container">
          {/* Left section */}
          <div className="nav-left">
            <div className="nav-item" onClick={() => navigate("/")}>
              ZEAL
            </div>
          </div>

          {/* Center icon */}
          <div className="nav-center">
            <img
              src={icon}
              alt="Icon"
              className="nav-icon"
              onClick={toggleDropdown}
              ref={buttonRef}
            />
          </div>

          {/* Right side: user info or start link */}
          <div className="nav-right">
            <div className="nav-items">
              {isAuthenticated ? (
                /* -------------- LOGGED IN -------------- */
                <div className="nav-item" onClick={() => navigate("/profile")}>
                  {userData ? userData.first_name : "Loading..."}
                  <img
                    src={
                      userData && userData.profilePictureUrl
                        ? userData.profilePictureUrl
                        : profilePic
                    }
                    alt={
                      userData
                        ? `${userData.first_name}'s avatar`
                        : "User avatar"
                    }
                    className="avatar"
                  />
                </div>
              ) : (
                /* -------------- NOT LOGGED IN -------------- */
                <>
                  {location.pathname === "/" && (
                    <div
                      className="nav-item"
                      onClick={() => navigate("/login")}
                    >
                      Start
                      <img
                        src={profilePic}
                        alt="Default avatar"
                        className="avatar"
                      />
                    </div>
                  )}

                  {location.pathname === "/register" && (
                    <div className="nav-item">
                      <Link to="/login">Login</Link>
                    </div>
                  )}

                  {location.pathname === "/login" && (
                    <div className="nav-item">
                      <Link to="/register">Register</Link>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <div className="nav-menu-icon" onClick={toggleDropdown}>
              &#9776;
            </div> */}
          </div>
        </div>
      </nav>

      {/* Expanded dropdown */}
      {isExpanded && (
        <div
          className="dropdown-overlay"
          ref={dropdownRef}
          onClick={() => setIsExpanded(false)}
        >
          <div className="expanded-menu" onClick={(e) => e.stopPropagation()}>
            <div className="line">
              <img src={line} alt="Line" className="line-img" />
            </div>
            <div className="dropdown-item" onClick={() => handleItemClick("/")}>
              Home
            </div>
            <div className="dropdown-item" onClick={() => handleItemClick("/")}>
              CHOOSE A GAME
            </div>

            {isAuthenticated ? (
              <>
                <div
                  className="dropdown-item"
                  onClick={() => handleItemClick("/profile")}
                >
                  PROFILE
                </div>
                <div className="dropdown-item" onClick={handleLogoutClick}>
                  LOG OUT
                </div>
              </>
            ) : (
              <>
                <div
                  className="dropdown-item"
                  onClick={() => handleItemClick("/login")}
                >
                  LOGIN
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => handleItemClick("/register")}
                >
                  REGISTER
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
