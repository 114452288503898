// src/components/Stats/Stats.js

import React, { useState, useEffect } from 'react';
import './Stats.css';
import LineChart from '../LineChart/LineChart';
import Leaderboard from "../Leaderboard/Leaderboard";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Stats({ refreshTokens, handleLogout }) {
  // -----------------------------
  //   State Variables
  // -----------------------------
  const [userProfile, setUserProfile] = useState(null);
  const [userGames, setUserGames] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingGames, setLoadingGames] = useState(true);
  const [error, setError] = useState(null);

  // Derived stats
  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0);
  const [draws, setDraws] = useState(0);
  const [winPercentage, setWinPercentage] = useState(0);
  const [lossPercentage, setLossPercentage] = useState(0);
  const [drawPercentage, setDrawPercentage] = useState(0);

  // Points progression data
  const [pointsData, setPointsData] = useState([]);
  const [dates, setDates] = useState([]);

  // Leaderboard states
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);

  // Server-side pagination/search states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10; // or whatever limit you prefer

  // Optional: If you still want to use filter in your request
  const [selectedFilter, setSelectedFilter] = useState('CITY');

  // Auth token and API
  const API_URL = process.env.REACT_APP_API_URL;
  let idToken = localStorage.getItem('idToken');

  // -----------------------------
  //   Fetch User Profile
  // -----------------------------
  useEffect(() => {
    const fetchUserProfile = async () => {
      const storedToken = localStorage.getItem('idToken');
      if (!storedToken) {
        setError("No token found, please log in.");
        setLoadingProfile(false);
        return;
      }

      try {
        let response = await fetch(`${API_URL}/profile/user-profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`,
          },
        });

        // Handle token expiry
        if (response.status === 401) {
          const tokenRefreshed = await refreshTokens();
          if (tokenRefreshed) {
            response = await fetch(`${API_URL}/profile/user-profile`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
              },
            });
          } else {
            handleLogout();
            return;
          }
        }

        if (!response.ok) {
          throw new Error('Failed to fetch user profile');
        }

        const data = await response.json();
        setUserProfile(data);
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError(err.message);
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchUserProfile();
  }, [API_URL, refreshTokens, handleLogout]);

  // -----------------------------
  //   Fetch User Games
  // -----------------------------
  useEffect(() => {
    const fetchUserGames = async () => {
      const storedToken = localStorage.getItem('idToken');
      if (!storedToken) {
        setError("No token found, please log in.");
        setLoadingGames(false);
        return;
      }

      try {
        let response = await fetch(`${API_URL}/profile/user-games`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`,
          },
        });

        if (response.status === 401) {
          const tokenRefreshed = await refreshTokens();
          if (tokenRefreshed) {
            response = await fetch(`${API_URL}/profile/user-games`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
              },
            });
          } else {
            handleLogout();
            return;
          }
        }

        if (!response.ok) {
          throw new Error('Failed to fetch user games');
        }

        const data = await response.json();
        setUserGames(data);
      } catch (err) {
        console.error('Error fetching user games:', err);
        setError(err.message);
      } finally {
        setLoadingGames(false);
      }
    };

    fetchUserGames();
  }, [API_URL, refreshTokens, handleLogout]);

  // -----------------------------
  //   Compute Win/Loss/Draw stats
  // -----------------------------
  useEffect(() => {
    if (userGames.length > 0) {
      let winsCount = 0, lossesCount = 0, drawsCount = 0;

      userGames.forEach((game) => {
        if (game.result === 'win') winsCount++;
        else if (game.result === 'loss') lossesCount++;
        else if (game.result === 'draw') drawsCount++;
      });

      setWins(winsCount);
      setLosses(lossesCount);
      setDraws(drawsCount);

      const totalGames = winsCount + lossesCount + drawsCount;
      if (totalGames > 0) {
        setWinPercentage((winsCount / totalGames) * 100);
        setLossPercentage((lossesCount / totalGames) * 100);
        setDrawPercentage((drawsCount / totalGames) * 100);
      } else {
        setWinPercentage(0);
        setLossPercentage(0);
        setDrawPercentage(0);
      }
    }
  }, [userGames]);

  // -----------------------------
  //   Points Progression
  // -----------------------------
  useEffect(() => {
    if (!loadingGames && userGames.length > 0) {
      const sortedGames = [...userGames].sort((a, b) => new Date(a.date) - new Date(b.date));

      const pointsArray = [];
      const dateArray = [];
      let cumulativePoints = 0;

      sortedGames.forEach((game) => {
        const points = game.pointsEarned || game.points || 0;
        cumulativePoints += points;
        pointsArray.push(cumulativePoints);

        const date = new Date(game.date);
        dateArray.push(isNaN(date) ? 'Invalid Date' : date.toLocaleDateString());
      });

      setPointsData(pointsArray);
      setDates(dateArray);
    } else {
      setPointsData([]);
      setDates([]);
    }
  }, [loadingGames, userGames]);

  // -----------------------------
  //   Fetch Leaderboard (Paginated)
  // -----------------------------
  useEffect(() => {
    // Wrap in an async function
    const fetchLeaderboard = async () => {
      const storedToken = localStorage.getItem('idToken');
      if (!storedToken) {
        setError("No token found, please log in.");
        setLoadingLeaderboard(false);
        return;
      }

      try {
        // Build query with page, limit, searchTerm, filter
        const queryParams = new URLSearchParams({
          page: currentPage,
          limit: itemsPerPage,
          filter: selectedFilter,
          searchTerm, // If your backend implements searching
        });

        let response = await fetch(`${API_URL}/leaderboard?${queryParams.toString()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`,
          },
        });

        if (response.status === 401) {
          const tokenRefreshed = await refreshTokens();
          if (tokenRefreshed) {
            response = await fetch(`${API_URL}/leaderboard?${queryParams.toString()}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
              },
            });
          } else {
            handleLogout();
            return;
          }
        }

        if (!response.ok) {
          throw new Error('Failed to fetch leaderboard data');
        }

        // Expect { totalUsers, totalPages, currentPage, data: [...] }
        const json = await response.json();
        setLeaderboardData(json.data || []);  // The chunk of users
        setTotalPages(json.totalPages || 1);
      } catch (err) {
        console.error('Error fetching leaderboard data:', err);
        setError(err.message);
      } finally {
        setLoadingLeaderboard(false);
      }
    };

    fetchLeaderboard();
  }, [
    currentPage,
    itemsPerPage,
    searchTerm,
    selectedFilter,
    API_URL,
    refreshTokens,
    handleLogout,
  ]);

  // -----------------------------
  //   Handlers: Search, Pagination
  // -----------------------------
  const handleSearchChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // reset to first page
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // -----------------------------
  //   Loading / Error States
  // -----------------------------
  if (loadingProfile || loadingGames) {
    return <p>Loading your stats...</p>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userProfile) {
    return <p>Unable to load your profile data.</p>;
  }

  // -----------------------------
  //   Render
  // -----------------------------
  return (
    <div className="stats-container">
      {/* Points Display */}
      <div className="points-display">
        {userProfile.points} POINTS
      </div>

      {/* Stats Grid */}
      <div className="stats-grid">
        <div className="stat-item">
          <CircularProgressbarWithChildren
            value={lossPercentage}
            strokeWidth={3}
            styles={buildStyles({
              textColor: '#1EB62D',
              pathColor: '#1EB62D',
              trailColor: '#0F5E17',
              textSize: '16px',
              strokeLinecap: 'butt',
            })}
          >
            <div className="circle-content">
              <div className="stat-number">{losses}</div>
              <div className="stat-text">LOST</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="stat-item">
          <CircularProgressbarWithChildren
            value={winPercentage}
            strokeWidth={3}
            styles={buildStyles({
              textColor: '#1EB62D',
              pathColor: '#1EB62D',
              trailColor: '#0F5E17',
              textSize: '16px',
              strokeLinecap: 'butt',
            })}
          >
            <div className="circle-content">
              <div className="stat-number">{wins}</div>
              <div className="stat-text">WON</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="stat-item">
          <CircularProgressbarWithChildren
            value={drawPercentage}
            strokeWidth={3}
            styles={buildStyles({
              textColor: '#1EB62D',
              pathColor: '#1EB62D',
              trailColor: '#0F5E17',
              textSize: '16px',
              strokeLinecap: 'butt',
            })}
          >
            <div className="circle-content">
              <div className="stat-number">{draws}</div>
              <div className="stat-text">TIED</div>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      {/* Separator */}
      <div className="separator">
        IN YOUR <span className="stat-number">{userGames.length}</span> GAMES
      </div>

      {/* Goals and Assists Stats */}
      <div className="ga-stats-section">
        <div className="ga-stats-item">
          <div className="stat-label">YOU SCORED</div>
          <div className="stat-value">{userProfile.goals}</div>
          <div className="stat-label">GOALS</div>
        </div>
        <div className="ga-stats-item">
          <div className="stat-label">YOU GAVE</div>
          <div className="stat-value">{userProfile.assists}</div>
          <div className="stat-label">ASSISTS</div>
        </div>
      </div>

      {/* Line Chart for Point Progression */}
      {!loadingGames && pointsData.length > 1 ? (
        <div className="point-progress-chart">
          <LineChart dataPoints={pointsData} dates={dates} title="Your Points Over Time" />
        </div>
      ) : (
        <p className="no-points-msg">
          {pointsData.length <= 1
            ? 'Play more games to see your points progression.'
            : 'Loading point progression...'}
        </p>
      )}

      {/* Leaderboard (server-paginated) */}
      {!loadingLeaderboard && leaderboardData.length > 0 ? (
        <Leaderboard
          leaderboardData={leaderboardData}
          currentUserId={userProfile._id}
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          onSearchChange={handleSearchChange}
          searchTerm={searchTerm}
        />
      ) : (
        <p>Loading leaderboard...</p>
      )}
    </div>
  );
}

export default Stats;
