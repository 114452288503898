import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CookieBanner from "./components/CookieBanner/CookieBanner";

import AppRoutes from "./routes/AppRoutes";
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  const refreshTokens = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      return false;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh tokens");
      }

      const { idToken, accessToken } = await response.json();
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("accessToken", accessToken);

      return true;
    } catch (error) {
      console.error("Error refreshing tokens:", error);
      handleLogout();
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Login sets isAuthenticated and all tokens
  const handleLogin = (idToken, accessToken, refreshToken) => {
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    setIsAuthenticated(true);
  };

  // Logout clears tokens
  const handleLogout = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);

    // Optional: if you also want to reset userData on logout
    setUserData(null);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* Pass setUserData as onUserFetch to Navbar so it can store the fetched user */}
        <Navbar
          isAuthenticated={isAuthenticated}
          handleLogout={handleLogout}
          refreshTokens={refreshTokens}
          onUserFetch={setUserData}
        />

        {/* Pass userData to AppRoutes so child routes (Home, Profile, etc.) can receive it */}
        <AppRoutes 
          isAuthenticated={isAuthenticated} 
          onLogin={handleLogin}
          refreshTokens={refreshTokens}
          handleLogout={handleLogout}
          userData={userData}
        />

        <Footer />
        <CookieBanner />

      </div>
    </Router>
  );
}

export default App;
