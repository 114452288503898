// getCroppedImg.js
export default function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageSrc;

    image.onload = () => {
      // Create a <canvas> matching the crop region size
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext("2d");

      // Draw that *exact* slice from the original image
      ctx.drawImage(
        image,
        croppedAreaPixels.x,      // start x in the original image
        croppedAreaPixels.y,      // start y in the original image
        croppedAreaPixels.width,  // how much width to take from original
        croppedAreaPixels.height, // how much height to take from original
        0,
        0,
        croppedAreaPixels.width,  // draw it to the canvas
        croppedAreaPixels.height
      );

      // Convert canvas to blob
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/png", 1);
    };

    image.onerror = (error) => reject(error);
  });
}
