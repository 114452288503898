import React, { useState, useRef, useEffect, useCallback } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import axios from "axios";
import { Carousel } from "../Carousel/Carousel";
import { GameCard } from "../GameCard/GameCard";
import Button from "../Button/Button3";
import "./GameFilter.css";
import moment from "moment";
import CarmenImage from "../../assets/images/carmen.png";

export const GameFilter = () => {
  const isSmallScreen = useMediaQuery("(max-width: 480px)");
  const filterVariant = isSmallScreen ? "medium" : "large";

  const carouselRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  const [activeFilters, setActiveFilters] = useState({
    level: null,
    date: null,
    gameName: null, // for place filter (stadium name)
  });

  const [filterType, setFilterType] = useState("date");
  const [currentWeek, setCurrentWeek] = useState({
    start: moment().startOf("isoWeek"),
    end: moment().endOf("isoWeek"),
  });

  const [games, setGames] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]); // store unique stadium names here

  // Level options can remain static if they're known in advance
  const levelOptions = ["Beginner", "Rising", "Champion"];

  // Drag state
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Fetch all games
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/games`);
        // filter out "finished" games if necessary
        const upcomingGames = response.data.filter((game) => game.status !== "finished");
        setGames(upcomingGames);

        // Extract unique stadium names
        const uniqueStadiumNames = [
          ...new Set(upcomingGames.map((game) => game.stadium?.name || "Unknown Stadium")),
        ];
        setPlaceOptions(uniqueStadiumNames);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };
    fetchGames();
  }, []);

  // Week change
  const changeWeek = (direction) => {
    setCurrentWeek((prevWeek) => {
      const newStart = moment(prevWeek.start).add(direction, "week");
      const newEnd = moment(prevWeek.end).add(direction, "week");
      return { start: newStart, end: newEnd };
    });
  };

  // Create array of days for the current week
  const weekDays = [];
  let day = currentWeek.start.clone();
  while (day.isSameOrBefore(currentWeek.end)) {
    weekDays.push({
      fullDate: day.format("dddd, DD.MM.YYYY"),
      dayName: day.format("dddd"),
      dateNumber: day.format("DD.MM.YYYY"),
    });
    day.add(1, "day");
  }

  // Handle filter clicks
  const handleFilterClick = (category, selectedFilter) => {
    setActiveFilters((prev) => ({
      ...prev,
      [category]: prev[category] === selectedFilter ? null : selectedFilter,
    }));
  };

  // Filter the games by date, level, place
  const filteredGames = games.filter((game) => {
    const levelMatches = !activeFilters.level || game.type === activeFilters.level;
    const dateMatches =
      !activeFilters.date ||
      moment(game.date).format("dddd, DD.MM.YYYY") === activeFilters.date;
    const placeMatches =
      !activeFilters.gameName || game.stadium.name === activeFilters.gameName;

    return levelMatches && dateMatches && placeMatches;
  });

  // Carousel auto-scroll
  const scrollRight = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const cardWidth = carousel.offsetWidth / 5;
      const maxScrollLeft = carousel.scrollWidth - carousel.offsetWidth;

      if (carousel.scrollLeft + carousel.offsetWidth < carousel.scrollWidth - cardWidth) {
        carousel.scrollBy({ left: cardWidth, behavior: "smooth" });
      } else {
        carousel.scrollTo({ left: maxScrollLeft, behavior: "smooth" });
        setTimeout(() => {
          carousel.scrollTo({ left: 0, behavior: "smooth" });
        }, 3000);
      }
    }
  };

  const startAutoScroll = useCallback(() => {
    stopAutoScroll();
    scrollIntervalRef.current = setInterval(() => {
      scrollRight();
    }, 3000);
  }, []);

  const stopAutoScroll = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  // Mouse drag logic
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const mouseDownHandler = (e) => {
      e.preventDefault();
      setIsDown(true);
      carousel.classList.add("active");
      setStartX(e.pageX - carousel.offsetLeft);
      setScrollLeft(carousel.scrollLeft);
      stopAutoScroll();
    };

    const mouseLeaveHandler = () => {
      setIsDown(false);
      carousel.classList.remove("active");
    };

    const mouseUpHandler = (e) => {
      e.preventDefault();
      setIsDown(false);
      carousel.classList.remove("active");
      setTimeout(() => {
        startAutoScroll();
      }, 1000);
    };

    const mouseMoveHandler = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - carousel.offsetLeft;
      const walk = x - startX;
      carousel.scrollLeft = scrollLeft - walk;
    };

    carousel.addEventListener("mousedown", mouseDownHandler);
    carousel.addEventListener("mouseleave", mouseLeaveHandler);
    carousel.addEventListener("mouseup", mouseUpHandler);
    carousel.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      carousel.removeEventListener("mousedown", mouseDownHandler);
      carousel.removeEventListener("mouseleave", mouseLeaveHandler);
      carousel.removeEventListener("mouseup", mouseUpHandler);
      carousel.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [startX, scrollLeft, isDown, startAutoScroll]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft = 0;
      startAutoScroll();
    }
    return () => {
      stopAutoScroll();
    };
  }, [startAutoScroll]);

  // Render filter options
  const renderFilterOptions = () => {
    switch (filterType) {
      case "date":
        return (
          <>
            <Carousel ref={carouselRef}>
              {weekDays.map((dayInfo, index) => (
                <div key={index} className="filter-option">
                  <Button
                    variant={filterVariant}
                    primaryText={dayInfo.dayName}
                    secondaryText={dayInfo.dateNumber}
                    onClick={() => handleFilterClick("date", dayInfo.fullDate)}
                    styleType={
                      activeFilters.date === dayInfo.fullDate ? "active" : "default"
                    }
                    onMouseEnter={stopAutoScroll}
                    onMouseLeave={startAutoScroll}
                  />
                </div>
              ))}
            </Carousel>
            <div className="carousel-header">
              <button className="carousel-nav left" onClick={() => changeWeek(-1)}>
                {"<"}
              </button>
              <p className="carousel-title">
                {currentWeek.start.format("DD.MM.YYYY")} - {currentWeek.end.format("DD.MM.YYYY")}
              </p>
              <button className="carousel-nav right" onClick={() => changeWeek(1)}>
                {">"}
              </button>
            </div>
          </>
        );
      case "level":
        return (
          <Carousel ref={carouselRef} centerItems={!isSmallScreen}>
            {levelOptions.map((level, index) => (
              <div key={index} className="filter-option">
                <Button
                  variant={filterVariant}
                  primaryText={level}
                  onClick={() => handleFilterClick("level", level)}
                  styleType={activeFilters.level === level ? "active" : "default"}
                  onMouseEnter={stopAutoScroll}
                  onMouseLeave={startAutoScroll}
                />
              </div>
            ))}
          </Carousel>
        );
      case "place":
        return (
          <Carousel ref={carouselRef} centerItems={!isSmallScreen}>
            {placeOptions.map((place, index) => (
              <div key={index} className="filter-option">
                <Button
                  variant={filterVariant}
                  primaryText={place}
                  onClick={() => handleFilterClick("gameName", place)}
                  styleType={activeFilters.gameName === place ? "active" : "default"}
                  onMouseEnter={stopAutoScroll}
                  onMouseLeave={startAutoScroll}
                />
              </div>
            ))}
          </Carousel>
        );
      default:
        return null;
    }
  };

  // Build each game card
  const backendUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";
  const gameCards = filteredGames.length > 0 ? (
    filteredGames.map((game) => {
      const localPath = game.stadium?.stadiumPicturePath;
      const imageSrc = localPath ? `${backendUrl}${localPath}` : CarmenImage;

      return (
        <GameCard
          key={game._id}
          style={
            isSmallScreen
              ? { width: "300px", height: "100px" }
              : { width: "140px", height: "174px" }
          }
          imageSrc={imageSrc}
          gameName={game.stadium.name}
          gameSubtitle={game.type}
          gameDay={game.date}
          gameTime={game.time}
          gameId={game._id}
        />
      );
    })
  ) : (
    <p className="no-games-message">No upcoming games available for the selected filters.</p>
  );

  return (
    <div className="game-filter-container">
      <p className="choose-by-title">CHOOSE BY...</p>
      <div className="filter-titles">
        {["date", "level", "place"].map((fType) => (
          <span
            key={fType}
            className={filterType === fType ? "active" : ""}
            onClick={() => setFilterType(fType)}
          >
            {fType.toUpperCase()}
          </span>
        ))}
      </div>

      <div className="filter-options-container">{renderFilterOptions()}</div>

      <div className={`filtered-games ${filteredGames.length <= 5 ? "centered" : ""}`}>
        {gameCards}
      </div>
    </div>
  );
};

export default GameFilter;
