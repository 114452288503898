import React, { useState } from 'react';
import './GameInfo.css';
import CarmenImage from "../../assets/images/carmen.png";
import Button from '../../components/Button/Button3';

/**
 * Helper to convert global outcome into user perspective:
 * - 'Team 1 wins' => userTeamIndex === 0 ? 'You Won!' : 'You Lost!'
 * - 'Team 2 wins' => userTeamIndex === 1 ? 'You Won!' : 'You Lost!'
 * - 'Draw'        => 'You Drew!'
 */
function getUserOutcome(gameOutcome, userTeamIndex) {
  if (!gameOutcome) return '';
  if (gameOutcome === 'Draw') return 'You Drew!';

  if (gameOutcome === 'Team 1 wins') {
    return userTeamIndex === 0 ? 'You Won!' : 'You Lost!';
  }
  if (gameOutcome === 'Team 2 wins') {
    return userTeamIndex === 1 ? 'You Won!' : 'You Lost!';
  }

  return '';
}

const GameInfo = ({
  time,
  date,
  gameName,
  tournament,
  placesLeft,
  onSignUp,
  isSignedUp,
  onWaitlistToggle,
  isWaitlisted,
  isGameFull,
  gameStatus,
  gameOutcome,
  userTeamIndex,
  userPlayerData,
  gameImagePath,
  teams = [[], []],
}) => {

  // We'll fetch images from the backend if present, else default to CarmenImage
  const backendUrl = process.env.REACT_APP_API_URL;
  const gameImage = gameImagePath ? `${backendUrl}${gameImagePath}` : CarmenImage;

  // NEW: local state to toggle the "See who's playing!" list
  const [showPlayers, setShowPlayers] = useState(false);

  // Flatten both teams into a single array, filtering out null/undefined
  const allPlayers = [...teams[0], ...teams[1]].filter(Boolean);

  // Toggle handler
  const handleTogglePlayers = () => {
    setShowPlayers((prev) => !prev);
  };

  // If game is finished, display user outcome
  if (gameStatus === 'finished') {
    const userResult = userTeamIndex !== null
      ? getUserOutcome(gameOutcome, userTeamIndex)
      : 'You did not participate.';

    const userGoals = userPlayerData?.goals || 0;
    const userAssists = userPlayerData?.assists || 0;
    const isSmallScreen = window.innerWidth <= 480;
    const computedImage = gameImagePath ? `${backendUrl}${gameImagePath}` : CarmenImage;

    const gameImageStyle = isSmallScreen
      ? { 
          backgroundImage: `url(${computedImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {};

    return (
      <div className="game-info-container" style={gameImageStyle}>
        <div className="game-info">
          <div className="game-info-sub1">
            <div className="game-time">{time}</div>
            <div className="game-date">{date}</div>
          </div>
          <div className="game-info-sub2">
            <div className="game-game-name">{gameName}</div>
            <div className="game-tournament">{tournament}</div>

            <div className="game-result">
              <h4>{userResult}</h4>
              {userTeamIndex !== null && (
                <>
                  <p>Goals: {userGoals}</p>
                  <p>Assists: {userAssists}</p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="game-image-container">
          <img src={computedImage} alt="Game" className="game-image" />
          <div className="noiseeffect"></div>
        </div>
      </div>
    );
  }

  // Otherwise, game is not finished => normal sign-up UI
  return (
    <div className="game-info-container">
      <div className="game-info">
        <div className="game-info-sub1">
          <div className="game-time">{time}</div>
          <div className="game-date">{date}</div>
        </div>
        <div className="game-info-sub2">
          <div className="game-game-name">{gameName}</div>
          <div className="game-tournament">{tournament}</div>

          <div className="game-places-left">
            <div className="game-places-left-sign">
              {placesLeft} places left
              <br />
              <span className="see-whos-playing" onClick={handleTogglePlayers}>
                See who's playing!
              </span>
            </div>

            {/* Move the player-list directly below the link, inside .game-places-left */}
            {showPlayers && (
              <div className="player-list">
                <h4>Players Signed Up</h4>
                <ul>
                  {allPlayers.map((player, idx) => (
                    <li key={idx}>
                      {player.first_name} {player.last_name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="sign-up-button-container">
          {/* sign up / waitlist logic */}
          {isSignedUp ? (
            <Button
              primaryText="CANCEL"
              variant="medium"
              onClick={onSignUp}
              styleType="cancel"
              className="sign-up-button"
            />
          ) : isWaitlisted ? (
            isGameFull ? (
              <Button
                primaryText="UNNOTIFY"
                secondaryText="FULL"
                variant="medium"
                onClick={onWaitlistToggle}
                styleType="cancel"
                className="waitlist-button"
              />
            ) : (
              <Button
                primaryText="JOIN"
                variant="medium"
                onClick={onSignUp}
                styleType="default"
                className="sign-up-button"
              />
            )
          ) : isGameFull ? (
            <Button
              primaryText="NOTIFY ME"
              secondaryText="FULL"
              variant="medium"
              onClick={onWaitlistToggle}
              styleType="default"
              className="waitlist-button"
            />
          ) : (
            <Button
              primaryText="JOIN"
              variant="medium"
              onClick={onSignUp}
              styleType="default"
              className="sign-up-button"
            />
          )}
        </div>
      </div>

      <div className="game-image-container">
        <img src={gameImage} alt="Game" className="game-image" />
        <div className="noiseeffect"></div>
      </div>
    </div>
  );
};

export default GameInfo;