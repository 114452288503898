import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './SubscriptionInfo.css';
import Button from '../Button/Button3';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const API_URL = process.env.REACT_APP_API_URL;

const SubscriptionInfo = ({ hideSubscriptionDetails = false, user }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem('idToken');

  // Format date as YY/MM/DD
  const formatDate = (isoString) => {
    if (!isoString) return 'N/A';
    const dt = new Date(isoString);
    const yy = String(dt.getFullYear()).slice(-2);
    const mm = String(dt.getMonth() + 1).padStart(2, '0');
    const dd = String(dt.getDate()).padStart(2, '0');
    return `${yy}/${mm}/${dd}`;
  };

  useEffect(() => {
    // Always fetch, even if we won't display details
    if (!token) {
      setLoading(false);
      return;
    }
  
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(`${API_URL}/subscription`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200 && response.data.subscription) {
          setSubscription(response.data.subscription);
        } else {
          setSubscription(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setSubscription(null);
        } else {
          console.error('Error fetching subscription:', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchSubscription();
  }, [token]);
  

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/subscription/cancel-subscription`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(response.data.message);
      setSubscription((prev) =>
        prev ? { ...prev, status: 'canceled', current_period_end: null } : null
      );
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      alert('Failed to cancel subscription. Please try again.');
    }
  };

  const createCheckoutSession = async (plan) => {
    if (!token) return;
    const stripe = await stripePromise;
    setIsProcessingCheckout(true); 
    try {
      const response = await axios.post(
        `${API_URL}/subscription/create-checkout-session`,
        { plan },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { id: sessionId } = response.data;
      const result = await stripe.redirectToCheckout({ sessionId });
      if (result.error) {
        console.error('Stripe error:', result.error.message);
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('An error occurred during the subscription process. Please try again.');
      setIsProcessingCheckout(false);
    }
  };

  // handle selecting any plan
  const handleSelectPlan = async (plan) => {
    // If not logged in, go to login
    if (!token) {
      navigate('/login');
      return;
    }
  
    const isSubscriptionPlan = ['Basic', 'Premium', 'Mate'].includes(plan);
  
    // If user picks a subscription plan:
    if (isSubscriptionPlan) {
      // If user already has an active sub => ask if they want to cancel
      if (subscription?.status === 'active') {
        const confirmChange = window.confirm(
          `You already have an active subscription (${subscription.type}).\n` +
          `This will cancel it and subscribe you to ${plan}.\n` +
          `You will still be able to use your previous credits until they expire. Continue?`
        );
        
        
        if (!confirmChange) return;
  
        await handleCancelSubscription(); // Cancels on the server
        // Now user.subscription.status should be "canceled" if it worked
      }
      // Then proceed to create new subscription
      await createCheckoutSession(plan);
    }
    else {
      // It's a one-time plan => no need to cancel subscription
      await createCheckoutSession(plan);
    }
  };
  

  const handleRenewNow = async () => {
    if (!subscription || subscription.status !== 'active' || !subscription.type) return;
    const currentPlan = subscription.type;
    const confirmRenew = window.confirm(
      `This will cancel your current subscription and renew ${currentPlan} now. Continue?`
    );
    if (!confirmRenew) return;

    await handleCancelSubscription();
    await createCheckoutSession(currentPlan);
  };

  const handleChangeSubscription = () => {
    setShowOptions(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isProcessingCheckout) {
    return (
      <div className="subscription-info-container">
        <h2>Processing...</h2>
        <p>Please wait, redirecting to checkout.</p>
      </div>
    );
  }

  // If hideSubscriptionDetails => just show the two rows (no subscription details)
  if (hideSubscriptionDetails) {
    return (
      <div className="subscription-info-container">
        <h2>PICK YOUR PLAN!</h2>
        <div className="subscription-options two-rows">
          {/* Row #1: Subscription Plans */}
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('Basic')}>
              <h3>BASIC</h3>
              <p>5 credits</p>
              <p>86zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Premium')}>
              <h3>PREMIUM</h3>
              <p>10 credits</p>
              <p>164zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Mate')}>
              <h3>MATE</h3>
              <p>20 credits</p>
              <p>280zł / month</p>
            </div>
          </div>
          {/* Row #2: One-time buys */}
          <h2>One time!</h2>
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('OneTime1')}>
              <h3>1 Game</h3>
              <p>No expiry date! </p>
              <p>Price: 28zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime5')}>
              <h3>5 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 135zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime10')}>
              <h3>10 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 240zł - one time</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If user not logged in => show the same two rows
  if (!token) {
    return (
      <div className="subscription-info-container">
        <h2>PICK YOUR PLAN!</h2>
        <div className="subscription-options two-rows">
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('Basic')}>
              <h3>BASIC</h3>
              <p>5 credits</p>
              <p>86zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Premium')}>
              <h3>PREMIUM</h3>
              <p>10 credits</p>
              <p>164zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Mate')}>
              <h3>MATE</h3>
              <p>20 credits</p>
              <p>280zł / month</p>
            </div>
          </div>
          <h2>One time!</h2>
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('OneTime1')}>
              <h3>1 Game</h3>
              <p>No expiry date! </p>
              <p>Price: 28zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime5')}>
              <h3>5 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 135zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime10')}>
              <h3>10 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 240zł - one time</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If logged in but not subscribed => also show the two rows
  const isSubscribed = subscription && subscription.status === 'active';
  if (!isSubscribed) {
    return (
      <div className="subscription-info-container">
        <h2>PICK YOUR PLAN!</h2>
        <div className="subscription-options two-rows">
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('Basic')}>
              <h3>BASIC</h3>
              <p>5 credits</p>
              <p>86zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Premium')}>
              <h3>PREMIUM</h3>
              <p>10 credits</p>
              <p>164zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Mate')}>
              <h3>MATE</h3>
              <p>20 credits</p>
              <p>280zł / month</p>
            </div>
          </div>
          <h2>One time!</h2>
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('OneTime1')}>
              <h3>1 Game</h3>
              <p>No expiry date! </p>
              <p>Price: 28zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime5')}>
              <h3>5 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 135zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime10')}>
              <h3>10 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 240zł - one time</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If logged in + subscribed => show subscription details plus a "Change" button
  const { type, status, current_period_end } = subscription;
  let credits = 0;
  let price = '';
  switch (type) {
    case 'Basic':
      credits = 5; price = '86zł'; break;
    case 'Premium':
      credits = 10; price = '164zł'; break;
    case 'Mate':
      credits = 20; price = '280zł'; break;
    default:
      credits = 0; price = '';
  }

  return (
    <div className="subscription-info-container">
      <h2>{type ? type.toUpperCase() : 'SUBSCRIPTION'}</h2>
      <p>{credits} credits per month</p>
      <div className="price-container">
        {price && <p className="price">{price}</p>}
        <p className="status">{status ? status.toUpperCase() : 'NO SUBSCRIPTION'}</p>
      </div>

      <div className="subscription-actions">
        <Button
          variant="small"
          primaryText="RENEW NOW"
          onClick={handleRenewNow}
          styleType="default"
        />
        <Button
          variant="small"
          primaryText="CHANGE"
          onClick={handleChangeSubscription}
          styleType="default"
        />
      </div>

      {showOptions && (
        <div className="subscription-options two-rows">
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('Basic')}>
              <h3>BASIC</h3>
              <p>5 credits</p>
              <p>86zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Premium')}>
              <h3>PREMIUM</h3>
              <p>10 credits</p>
              <p>164zł / month</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('Mate')}>
              <h3>MATE</h3>
              <p>20 credits</p>
              <p>280zł / month</p>
            </div>
          </div>
          <div className="subscription-options-row">
            <div className="option" onClick={() => handleSelectPlan('OneTime1')}>
              <h3>1 Game</h3>
              <p>No expiry date! </p>
              <p>Price: 28zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime5')}>
              <h3>5 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 135zł - one time</p>
            </div>
            <div className="option" onClick={() => handleSelectPlan('OneTime10')}>
              <h3>10 Games</h3>
              <p>No expiry date!</p>
              <p>Price: 240zł - one time</p>
            </div>
          </div>
        </div>
      )}

      <div className="cancel-subscription-wrapper">
        <span
          className="cancel-subscription-link"
          onClick={() => {
            if (window.confirm('Are you sure you want to cancel your subscription?')) {
              handleCancelSubscription();
            }
          }}
        >
          Cancel Subscription
        </span>
        <p className="cancel-subscription-text">
          You can still use your credits until {formatDate(current_period_end)}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
