import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../screens/Home/Home';
import Profile from '../screens/Profile/Profile';
import GameDetails from '../screens/GameDetails/GameDetails';
import LoginRoutes from './LoginRoutes';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import Terms from '../components/PrivacyPolicy/Terms';
import CookiesPolicy from '../components/PrivacyPolicy/CookiesPolicy';

function AppRoutes({ 
  isAuthenticated, 
  onLogin, 
  refreshTokens, 
  handleLogout,
  userData
}) {
  return (
    <Routes>
      <Route path="/" element={<Home user={userData} />} />   {/* pass userData here */}
      <Route path="/games/:gameId" element={<GameDetails />} />
      <Route path="/*" element={<LoginRoutes onLogin={onLogin} />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/cookies" element={<CookiesPolicy />} />

      {isAuthenticated && (
        <Route 
          path="/profile" 
          element={<Profile 
            refreshTokens={refreshTokens} 
            handleLogout={handleLogout}
            user={userData}
          />}
        />
      )}
    </Routes>
  );
}

export default AppRoutes;
