import React, { useState, useEffect } from 'react';
import Button from '../Button/Button3';
import Modal from '../Modal/Modal'; // Your reusable modal component
import CookiesPolicy from '../PrivacyPolicy/CookiesPolicy';
import './CookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const cookiesConsent = localStorage.getItem('cookiesConsent');
    if (!cookiesConsent) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesConsent', 'accepted');
    setShowBanner(false);
  };

  const refuseCookies = () => {
    localStorage.setItem('cookiesConsent', 'rejected');
    setShowBanner(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner">
          <p>
            We use cookies to ensure you get the best experience on our website.
            <span className="cookie-learn-more" onClick={openModal}>
              {" "}Learn more
            </span>.
          </p>
          <div className="cookie-buttons">
            <Button variant="small" primaryText="Accept" onClick={acceptCookies} />
            <Button variant="small" primaryText="Refuse" onClick={refuseCookies} />
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          ContentComponent={CookiesPolicy}
        />
      )}
    </>
  );
};

export default CookieBanner;
