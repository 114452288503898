// Home.js

import React from "react";
import './Home.css';
import { Frame } from "../../components/Frame/Frame";
import { GameFilter } from "../../components/GameFilter/GameFilter";
import { AboutUs } from '../../components/AboutUs/AboutUs';
import SubscriptionInfo from "../../components/SubscriptionInfo/SubscriptionInfo";

function Home({ user }) {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-text">
          <h1>ZEAL</h1>
          <p>CHOOSE YOUR NEXT MOVE</p>
        </div>
        <div className="noiseeffect"></div>
      </div>

      {/* Upcoming Games Section */}
      <div className="upcoming-games">
        <Frame />
      </div>

      <SubscriptionInfo hideSubscriptionDetails />

      <GameFilter />

      <AboutUs />

    </div>
  );
}

export default Home;
