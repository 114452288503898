import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import GameInfo from "../../components/GameInfo/GameInfo"; 
import Map from "../../components/Map/Map";
import Host from "../../components/Host/Host";
import "./GameDetails.css";

const API_URL = process.env.REACT_APP_API_URL || "";

/**
 * Helper function to find the user in the game's teams array.
 * Returns:
 *   teamIndex = 0 or 1 if found, else null
 *   playerData = the user object from that team, or null if not found
 */
function findUserInGame(game, userEmail) {
  if (!game || !game.teams) return { teamIndex: null, playerData: null };

  // Check Team 0
  for (let i = 0; i < game.teams[0].length; i++) {
    const p = game.teams[0][i];
    if (p && p.email === userEmail) {
      return { teamIndex: 0, playerData: p };
    }
  }

  // Check Team 1
  for (let j = 0; j < game.teams[1].length; j++) {
    const p = game.teams[1][j];
    if (p && p.email === userEmail) {
      return { teamIndex: 1, playerData: p };
    }
  }

  // Not found
  return { teamIndex: null, playerData: null };
}

const GameDetails = () => {
  const { gameId } = useParams(); 
  const [game, setGame] = useState(null); 
  const [isSignedUp, setIsSignedUp] = useState(false); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [onWaitlist, setOnWaitlist] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  /******************************************************
   * Fetch the game + user data on mount or when gameId changes
   ******************************************************/
  useEffect(() => {
    fetchGameDetails();
    // eslint-disable-next-line
  }, [gameId]);

  const fetchGameDetails = async () => {
    try {
      // 1) Fetch the game
      const response = await axios.get(`${API_URL}/games/${gameId}`);
      setGame(response.data);

      // 2) If user is logged in, fetch user profile
      const idToken = localStorage.getItem("idToken");
      if (idToken) {
        const userResponse = await axios.get(
          `${API_URL}/profile/user-profile`,
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
        const userData = userResponse.data;

        // Save their email in state for identifying them in teams
        setUserEmail(userData.email);

        // Check if the user is signed up (i.e. in either team)
        const userInTeam = response.data.teams.some((team) =>
          team.some((player) => player && player.email === userData.email)
        );
        setIsSignedUp(userInTeam);

        // Check if the user is on the waitlist
        const userOnWaitlist = response.data.waitlist.some(
          (waitlistUser) => waitlistUser.email === userData.email
        );
        setOnWaitlist(userOnWaitlist);
      }
    } catch (err) {
      console.error("Error fetching game details:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  /******************************************************
   * Waitlist logic
   ******************************************************/
  const handleJoinWaitlist = async () => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      navigate('/login');
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL}/games/waitlist/${gameId}`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      alert(response.data.message);
      setOnWaitlist(true);
      fetchGameDetails();
    } catch (error) {
      alert(error.response?.data?.error || "Error joining waitlist.");
    }
  };

  const handleLeaveWaitlist = async () => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      navigate('/login');
      return;
    }
    try {
      const response = await axios.delete(
        `${API_URL}/games/waitlist/${gameId}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      alert(response.data.message);
      setOnWaitlist(false);
      fetchGameDetails();
    } catch (error) {
      alert(error.response?.data?.error || "Error leaving waitlist.");
    }
  };

  /******************************************************
   * Signup logic
   ******************************************************/
  const handleSignup = async () => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      navigate('/login');
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL}/games/signup/${gameId}`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      alert(response.data.message || "Signed up successfully for the game!");
      setIsSignedUp(true);
      fetchGameDetails();
    } catch (error) {
      console.error("Error signing up for the game:", error);
      alert(error.response?.data?.error || "Error signing up for the game.");
    }
  };

  /******************************************************
   * Cancel signup logic
   ******************************************************/
  const handleCancelSignup = async () => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      navigate('/login');
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL}/games/cancel-signup/${gameId}`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      alert("You have successfully canceled your signup for the game.");
      setIsSignedUp(false);
      fetchGameDetails();
    } catch (error) {
      console.error("Error canceling signup for the game:", error);
      alert(error.response?.data?.error || "Error canceling your signup for the game.");
    }
  };

  /******************************************************
   * Utility: Calculate places left
   ******************************************************/
  const calculatePlacesLeft = (game) => {
    if (!game || !game.teams) return 0;
    const totalSlots = game.teams.reduce((sum, team) => sum + team.length, 0);
    const occupiedSlots = game.teams.reduce(
      (sum, team) => sum + team.filter((player) => player !== null).length,
      0
    );
    return totalSlots - occupiedSlots;
  };
  const isGameFull = game ? calculatePlacesLeft(game) === 0 : false;

  /******************************************************
   * Render logic
   ******************************************************/
  if (loading) {
    return <p>Loading game details...</p>;
  }

  if (error) {
    return (
      <p>Error fetching game details: {error.response?.data?.error || error.message}</p>
    );
  }

  if (!game) {
    return <p>Game not found.</p>;
  }

  // Identify the user's team index (0 or 1) and the player's data from the game doc
  let userTeamIndex = null;
  let userPlayerData = null;
  if (userEmail) {
    const { teamIndex, playerData } = findUserInGame(game, userEmail);
    userTeamIndex = teamIndex;
    userPlayerData = playerData;
  }

  // Format date
  const gameDate = new Date(game.date).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="game-details-container">
      <GameInfo
        time={game.time}
        date={gameDate}
        gameName={game.stadium?.name}
        tournament={game.type}
        placesLeft={calculatePlacesLeft(game)}
        onSignUp={isSignedUp ? handleCancelSignup : handleSignup}
        isSignedUp={isSignedUp}
        onWaitlistToggle={onWaitlist ? handleLeaveWaitlist : handleJoinWaitlist}
        isWaitlisted={onWaitlist}
        isGameFull={isGameFull}
        gameStatus={game.status}
        gameOutcome={game.result?.outcome}
        userTeamIndex={userTeamIndex}
        userPlayerData={userPlayerData}
        gameImagePath={game.stadium?.stadiumPicturePath}
        teams={game.teams}
        />

      {/* Map & Host remain the same */}
      <Map 
      address={game.stadium?.address}
      mapPath={game.stadium?.stadiumMapPath}
      gameName={game.stadium?.name}
      />
      <Host host={game.host} />
    </div>
  );
};

export default GameDetails;
