import React from "react";
import { useNavigate } from "react-router-dom";
import "./GameCard.css";

export const GameCard = ({ 
  imageSrc, 
  gameName, 
  gameSubtitle, 
  gameDay, 
  gameTime, 
  gameId, 
  className, 
  style = {} 
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/games/${gameId}`); // Navigate only if gameId is valid
  };

  // Helper function to format the day and time
  const formatGameDateTime = (dayString, timeString) => {
    if (!dayString || !timeString) {
      return "Date and time not available"; // Handle undefined or null values
    }

    const date = new Date(dayString);
    if (isNaN(date)) {
      return "Invalid Date";
    }

    // Format the day (e.g., "Mon")
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" }); // Short form: Mon, Tue, etc.
    // Format the date in MM/DD format
    const dateFormatted = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time in 24-hour format
    const timeFormatted = new Date(`1970-01-01T${timeString}`).toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${dayOfWeek}, ${dateFormatted} at ${timeFormatted}`;
  };

  return (
    <div 
      className={`game-card-container ${className}`} 
      onClick={handleClick} 
      style={style}
    >
      <div className="game-card">
        <div className="image-wrapper">
          <img 
            src={imageSrc || "path/to/default-image.png"} 
            alt={`${gameName || "Unknown Game"} Background`} 
            className="card-image" 
          />
          
          <div className="overlay-gradient"></div>
          <div className="overlay-content">
            <div className="game-name">{gameName || "Unknown Game"}</div>
            <div className="game-subtitle">{gameSubtitle || "Unknown Type"}</div>
          </div>
        </div>
      </div>
      <div className="game-day">
        {formatGameDateTime(gameDay, gameTime)}
      </div>
    </div>
  );
};
