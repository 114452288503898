import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../components/Button/Button3";
import "./ConfirmAccount.css";

function ConfirmAccount({ onLogin }) {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend
  const [timer, setTimer] = useState(0); // Timer state
  const navigate = useNavigate();
  const location = useLocation();

  // Pre-fill email and password from location state
  useEffect(() => {
    if (location.state) {
      setEmail(location.state.email || "");
      setPassword(location.state.password || "");
    }
  }, [location]);

  // Countdown timer logic
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Decrement timer by 1 every second
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false); // Enable the button when timer reaches 0
    }
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [timer]);

  const handleConfirm = async () => {
    if (!confirmationCode || !email || !password) {
      setMessage("Please fill in all fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/confirm`, {
        email,
        confirmationCode,
        password,
      });

      if (response.data && response.data.IdToken) {
        const { IdToken, AccessToken, RefreshToken } = response.data;
        setMessage("Account confirmed and logged in successfully!");
        onLogin(IdToken, AccessToken, RefreshToken);
        navigate("/");
      } else {
        setMessage("No valid tokens received from the backend.");
      }
    } catch (error) {
      setMessage(error.response?.data?.error || "Account confirmation failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (!email) {
      setMessage("Email is missing. Cannot resend code.");
      return;
    }

    setResendDisabled(true);
    setTimer(30); // Start 30-second timer

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/resend-confirmation-code`,
        { email }
      );
      setMessage(response.data.message || "A new code has been sent to your email.");
    } catch (error) {
      setMessage(error.response?.data?.error || "Could not resend code.");
      setResendDisabled(false); // Re-enable button in case of error
      setTimer(0); // Reset timer in case of error
    }
  };

  return (
    <div className="confirm-container">
      <div className="confirm-box">
        <h2>Confirm Your Account</h2>
        <p className="instruction-text">
          A confirmation code has been sent to your email: 
          <strong> {email || "your email address"} </strong>. Please enter it below.
        </p>
        <input
          type="text"
          placeholder="Enter confirmation code"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          className="input-field"
        />
        <Button
          variant="small"
          primaryText={loading ? "..." : "Confirm"}
          onClick={handleConfirm}
          styleType="default"
        />

        {/* Resend Code Section */}
        <div className="resend-container">
          <p className="resend-text">
            {resendDisabled ? (
              `Resend again in ${timer} seconds`
            ) : (
              <span className="resend-link" onClick={handleResendCode}>
                Resend Code
              </span>
            )}
          </p>
        </div>

        {/* Display any messages */}
        <p className="message">{message}</p>
      </div>
    </div>
  );
}

export default ConfirmAccount;
