import React from "react";
import Button from "../Button/Button3";
import "./Modal.css";

const PrivacyModal = ({ onClose, ContentComponent }) => {
  return (
    <div className="privacy-modal-overlay">
      <div className="privacy-modal">
        <div className="privacy-modal-content">
          {ContentComponent ? <ContentComponent /> : <p>No content provided.</p>}
        </div>
        <Button variant="small" primaryText="Close" onClick={onClose} />
      </div>
    </div>
  );
};

export default PrivacyModal;
