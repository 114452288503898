import React from 'react';
import './Map.css'; 
import MapImage from "../../assets/images/map.png"; // Default fallback map image

const Map = ({ address, mapPath, gameName }) => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const mapSrc = mapPath ? `${backendUrl}${mapPath}` : MapImage;
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address || '')}`;

  return (
    <div className="map-container">
      <div className="map-image">
        <img src={mapSrc} alt="Map Location" />
      </div>
      <div className="map-info">
        <h3>{gameName}</h3>
        <p>{address}</p>
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
          See in maps...
        </a>
      </div>
    </div>
  );
};

export default Map;
