import React, { useState } from "react";
import "./Host.css";
import DefaultHostImage from "../../assets/images/host.png";
import Button from "../../components/Button/Button3";

const Host = ({ host }) => {
  const hostName = `${host.first_name} ${host.last_name}`;
  const [showContactCard, setShowContactCard] = useState(false);

  // 1) If your backend returns a full URL (e.g. http://...), use it directly
  // 2) If your backend returns a relative path (e.g. /uploads/...), prepend the server URL
  const backendUrl = process.env.REACT_APP_API_URL || "http://localhost:5000";

  let hostImgSrc = DefaultHostImage; // fallback
  if (host.hostPicture) {
    // If hostPicture is already a full URL (starts with http/https), use it as-is
    if (host.hostPicture.startsWith("http")) {
      hostImgSrc = host.hostPicture;
    } else {
      // Otherwise, assume it's a relative path like "/uploads/admins/file.jpg"
      hostImgSrc = `${backendUrl}${host.hostPicture}`;
    }
  }

  const hostDescription =
    host.description ||
    `I am excited to host this friendly match today and look forward to 
     seeing some excellent football. Let's remember to play with respect and 
     sportsmanship, and most importantly, have fun out there!`;

  const handleContactClick = () => setShowContactCard(true);
  const handleCloseCard = () => setShowContactCard(false);

  return (
    <div className="host-container">
      <div className="host-info">
        <h1>{hostName}</h1>
        <h2>HOST</h2>
        <p>{hostDescription}</p>
        <Button
          variant="small"
          primaryText="CONTACT"
          styleType="default"
          onClick={handleContactClick}
        />
      </div>

      <div className="host-image">
        <img src={hostImgSrc} alt={`Host ${hostName}`} />
      </div>

      {showContactCard && (
        <div className="contact-card-overlay" onClick={handleCloseCard}>
          <div className="contact-card" onClick={(e) => e.stopPropagation()}>
            <h3>Contact {hostName}</h3>
            <p>
              <strong>Email:</strong> {host.email}
            </p>
            <p>
              <strong>Phone:</strong> {host.phone_number}
            </p>
            <Button
              variant="small"
              primaryText="CLOSE"
              styleType="default"
              onClick={handleCloseCard}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Host;
