import React, { useRef, useEffect, useCallback, useState } from "react";
import axios from "axios";
import { Carousel } from "../Carousel/Carousel";
import { GameCard } from "../GameCard/GameCard";
import "./Frame.css";

import CarmenImage from "../../assets/images/carmen.png";

export const Frame = () => {
  const [games, setGames] = useState([]);
  const carouselRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Fetch games from the backend
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/games`);
        // Filter to only include upcoming ones
        const upcomingGames = response.data.filter((game) => game.status !== "finished");
        setGames(upcomingGames);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };

    fetchGames();
  }, []);

  // Handle signup when user clicks the "Signup" button
  const handleSignup = async (gameId) => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) {
      alert("Please log in to sign up for this game.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/games/signup/${gameId}`,
        {},
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      alert("Signed up successfully for the game!");
    } catch (error) {
      console.error("Error signing up for the game:", error);
      alert("Error signing up for the game.");
    }
  };

  // Auto-scroll logic
  const startAutoScroll = useCallback(() => {
    stopAutoScroll();
    scrollIntervalRef.current = setInterval(() => {
      scrollRight();
    }, 3000);
  }, []);

  const stopAutoScroll = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  const scrollRight = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const cardWidth = carousel.offsetWidth / 6;
      const maxScrollLeft = carousel.scrollWidth - carousel.offsetWidth;

      if (carousel.scrollLeft + carousel.offsetWidth < carousel.scrollWidth - cardWidth) {
        carousel.scrollBy({ left: cardWidth, behavior: "smooth" });
      } else {
        carousel.scrollTo({ left: maxScrollLeft, behavior: "smooth" });
        setTimeout(() => {
          carousel.scrollTo({ left: 0, behavior: "smooth" });
        }, 3000);
      }
    }
  };

  const scrollLeft = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const cardWidth = carousel.offsetWidth / 6;
      carousel.scrollBy({ left: -cardWidth, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft = 0;
      startAutoScroll();
    }
    return () => {
      stopAutoScroll();
    };
  }, [startAutoScroll]);

  const backendUrl = process.env.REACT_APP_API_URL;

  return (
    <div
      className="frame"
      onMouseEnter={stopAutoScroll}
      onMouseLeave={startAutoScroll}
    >
      <div className="carousel-header">
        <button className="carousel-nav left" onClick={scrollLeft}>
          {"<"}
        </button>
        <h2 className="carousel-title">UPCOMING GAMES</h2>
        <button className="carousel-nav right" onClick={scrollRight}>
          {">"}
        </button>
      </div>

      {games.length > 0 ? (
        <Carousel ref={carouselRef} className={games.length <= 5 ? "centered" : ""}>
          {games.map((game) => {
            // Build local path -> full URL
            const localPath = game.stadium?.stadiumPicturePath;
            const imageSrc = localPath ? `${backendUrl}${localPath}` : CarmenImage;

            return (
              <GameCard
                style={{ width: "140px", height: "174px" }}
                key={game._id}
                imageSrc={imageSrc}
                gameName={game.stadium?.name || "Unknown Stadium"}
                gameSubtitle={game.type || "Unknown Type"}
                gameDay={game.date || "Unknown Date"}
                gameTime={game.time || "Unknown Time"}
                onSignup={() => handleSignup(game._id)}
                gameId={game._id}
                className="game-card-container"
              />
            );
          })}
        </Carousel>
      ) : (
        <p className="no-games-message">No upcoming games available.</p>
      )}
    </div>
  );
};

export default Frame;
